import React, { useContext } from 'react'
import styled, { withTheme } from 'styled-components'
import { Color } from 'csstype'

import { StoreContext } from 'src/contexts/Store'
import { Theme } from 'src/types'
import { breakpoints } from 'src/styles'

import StationGridItem from './StationGridItem'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${breakpoints.mobile`
     grid-template-columns: repeat(2, 1fr);
  `};

  ${breakpoints.mobileLandscape`
     grid-template-columns: repeat(3, 1fr);
  `};

  ${breakpoints.tablet`
    grid-template-columns: repeat(4, 1fr);
  `};

  ${breakpoints.tabletLandscape`
    grid-template-columns: repeat(4, 1fr);
  `};
  ${breakpoints.desktop`
    grid-template-columns: repeat(5, 1fr);
  `};

  ${breakpoints.largeDesktop`
    grid-template-columns: repeat(7, 1fr);
  `};
  background-color: ${({ theme }): Color => theme.colors.mainBackground};
`

const StationGrid: React.FC<Theme> = () => {
  const store = useContext(StoreContext)
  const { state } = store
  const { stations } = state

  return (
    <Grid>
      {stations.map(
        (item): JSX.Element => (
          <StationGridItem key={item.id} station={item} selected={item.selected} />
        )
      )}
    </Grid>
  )
}

export default withTheme(StationGrid)
