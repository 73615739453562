import React, { SyntheticEvent, useContext } from 'react'
import styled, { withTheme } from 'styled-components'

import { StoreContext } from 'src/contexts/Store'
import { Theme } from 'src/types'
import { PreviousIcon, PlayIcon, StopIcon, NextIcon } from 'src/components/icons'
import { dispatcher } from 'src/utils'
import { EVENT_CLOSE_MODALS } from 'src/constants'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'previous playback next';
`

const PreviousControl = styled.div`
  grid-area: previous;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const PlaybackControl = styled.div`
  grid-area: playback;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const NextControl = styled.div`
  grid-area: next;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const AudioControls: React.FC<Theme> = ({ theme, className }) => {
  const store = useContext(StoreContext)
  const { state, actions } = store
  const { selectedStation, isPlay, isLoading } = state

  if (!selectedStation) return <></>

  const togglePlay = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    actions.togglePlay()
  }

  const setPrevStation = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    actions.setPrevStation()
  }

  const setNextStation = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    actions.setNextStation()
  }

  const stopIconColor = isLoading ? theme.colors.loading : undefined

  return (
    <GridContainer className={className}>
      <PreviousControl onClick={setPrevStation}>
        <PreviousIcon />
      </PreviousControl>
      <PlaybackControl onClick={togglePlay}>{isPlay ? <StopIcon fill={stopIconColor} /> : <PlayIcon />}</PlaybackControl>
      <NextControl onClick={setNextStation}>
        <NextIcon />
      </NextControl>
    </GridContainer>
  )
}

export default withTheme(AudioControls)
AudioControls.displayName = 'AudioControls'
