import React, { SyntheticEvent, useContext } from 'react'
import styled, { withTheme } from 'styled-components'

import { UiContext } from 'src/contexts/Ui'
import { IconState, Theme } from 'src/types'
import { EVENT_CLOSE_MODALS } from 'src/constants'
import { MoonIcon } from 'src/components/icons'
import { dispatcher } from 'src/utils'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const ThemeControl: React.FC<Theme> = ({ className, theme }) => {
  const store = useContext(UiContext)
  const { state, actions } = store
  const { themeName } = state

  const dark = themeName === 'dark'
  const iconFill = theme.colors.themeControl[IconState[dark ? 'active' : 'normal']]

  const toggleTheme = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    actions.toggleTheme()
  }

  return (
    <Container className={className} onClick={toggleTheme}>
      <MoonIcon fill={iconFill} />
    </Container>
  )
}

export default withTheme(ThemeControl)
ThemeControl.displayName = 'ThemeControl'
