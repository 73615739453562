const serializeValue = (value: string | string[] | number | object | null): string => {
  if (value instanceof Array) return value.join(',')
  if (typeof value === 'object') return JSON.stringify(value)
  if (typeof value === 'number') return `${value}`
  return value
}

export const store = (key: string, value: string | string[] | number | object | null): void => {
  try {
    if (!value) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, serializeValue(value))
    }
  } catch (e) {
    // saving error
  }
}

export const getString = (key: string): string | null => {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    // error reading value
  }
  return null
}

export const getObject = (key: string): object | null => {
  const value = getString(key)
  if (value) return JSON.parse(value)
  return {}
}

export const getStringArray = (key: string): string[] => {
  const value = getString(key)
  if (value) return value.split(',')
  return []
}

export const getNumber = (key: string): number | null => {
  const value = getString(key)
  if (value) return parseInt(value, 0)
  return null
}

export const removeKeys = (keys: string[]): void => {
  try {
    keys.forEach((key): void => {
      localStorage.removeItem(key)
    })
  } catch (e) {
    // remove error
  }
}
