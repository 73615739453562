import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'
import { Color } from 'csstype'

import { Theme } from 'src/types'
import { CheckIcon } from 'src/components/icons'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  color: ${({ theme }): Color => theme.colors.popover.text};
  :hover {
    color: ${({ theme }): Color => theme.colors.popover.hover.text};
    background-color: ${({ theme }): Color => theme.colors.popover.hover.background};
  }
`

const Label = styled.div`
  flex-grow: 1;
  margin-right: 0.75rem;
  :hover {
    color: ${({ theme }): Color => theme.colors.popover.hover.text};
`

const IconContainer = styled.div`
  flex-grow: 0;
`

interface Props extends Theme {
  label: string
  active: boolean
  onClick: (e: SyntheticEvent) => void
}

const FilterOption: React.FC<Props> = ({ label, active, onClick, theme }) => {
  const iconFill = active ? theme.colors.popover.text : 'transparent'
  return (
    <Container theme={theme} onClick={onClick}>
      <Label theme={theme}>{label}</Label>
      <IconContainer theme={theme}>
        <CheckIcon theme={theme} fill={iconFill} />
      </IconContainer>
    </Container>
  )
}

export default FilterOption
FilterOption.displayName = 'FilterOption'
