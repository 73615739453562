import React from 'react'
import SmartBanner from 'react-smartbanner'
import 'react-smartbanner/dist/main.css'

import { StoreProvider } from 'src/contexts/Store'
import { UiProvider } from 'src/contexts/Ui'
import AudioStream from 'src/components/media/AudioStream'
import { HtmlHead, Main, Header, Body, Footer } from 'src/components/layout'

const App: React.FC = () => (
  <UiProvider>
    <StoreProvider>
      <SmartBanner title="Zapp FM" author="Why The Face B.V." />
      <HtmlHead />
      <Main>
        <Header />
        <Body />
        <Footer />
        <AudioStream />
      </Main>
    </StoreProvider>
  </UiProvider>
)

export default App
App.displayName = 'App'
