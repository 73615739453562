import React, { SyntheticEvent, useContext } from 'react'
import styled, { withTheme } from 'styled-components'

import { StoreContext } from 'src/contexts/Store'
import { IconState, Theme } from 'src/types'
import { EVENT_CLOSE_MODALS, FAVORITES_FILTER_VALUE } from 'src/constants'
import { FavoriteIcon } from 'src/components/icons'
import { dispatcher } from 'src/utils'

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
`

const FavoriteControl: React.FC<Theme> = ({ className, theme }) => {
  const store = useContext(StoreContext)
  const { state, actions } = store
  const { filter } = state

  const active = filter === FAVORITES_FILTER_VALUE
  const iconFill = theme.colors.favoriteControl[IconState[active ? 'active' : 'normal']]

  const toggleFilter = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    actions.toggleFavoritesFilter()
  }

  return (
    <Container className={className} onClick={toggleFilter}>
      <FavoriteIcon fill={iconFill} />
    </Container>
  )
}

export default withTheme(FavoriteControl)
FavoriteControl.displayName = 'FavoriteControl'
