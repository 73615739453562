import React from 'react'

import { Theme, Icon } from 'src/types'
import { withTheme } from 'styled-components'

interface Props extends Theme, Icon {}

const CheckIcon: React.FC<Props> = ({ theme, size = 1.2, fill = theme.colors.control }) => {
  const width = `${size}rem`
  const height = `${size}rem`

  return (
    <svg width={width} height={height} viewBox="0 0 96 96">
      <path
        d="M75.5 35.2c0 .9-.3 1.8-1 2.4L48.8 63.3 44 68.1c-.7.7-1.5 1-2.4 1s-1.8-.3-2.4-1l-4.8-4.8-12.9-12.9c-.7-.7-1-1.5-1-2.4s.3-1.8 1-2.4l4.8-4.8c.7-.7 1.5-1 2.4-1s1.8.3 2.4 1l10.4 10.5L64.8 28c.7-.7 1.5-1 2.4-1 .9 0 1.8.3 2.4 1l4.8 4.8c.8.6 1.1 1.4 1.1 2.4z"
        fill={fill}
      />
    </svg>
  )
}

export default withTheme(CheckIcon)
CheckIcon.displayName = 'CheckIcon'
