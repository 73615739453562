import React, { SyntheticEvent, useContext } from 'react'
import styled, { withTheme } from 'styled-components'
import { Color } from 'csstype'

import { StoreContext } from 'src/contexts/Store'
import {
  FAVORITES_FILTER_VALUE,
  MOST_PLAYED_FILTER_VALUE,
  RECENTLY_PLAYED_FILTER_VALUE,
  TEXT_ALL_STATIONS,
  TEXT_MY_FAVORITE_STATIONS,
  TEXT_RECENTLY_PLAYED_STATIONS,
  TEXT_MOST_PLAYED_STATIONS,
  CLEAR_COMMAND_VALUE,
  EVENT_CLOSE_MODALS
} from 'src/constants'
import { Theme } from 'src/types'
import { breakpoints } from 'src/styles'
import { SearchIcon, ClearIcon } from 'src/components/icons'
import { dispatcher } from 'src/utils'

const height = 2.2
const radius = 0.5

const wideHeight = 1.875
const wideRadius = 1.875

const Container = styled.div``

const InputContainer = styled.div`
  height: ${height}rem;
  ${breakpoints.tablet`
    height: ${wideHeight}rem;
  `};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  margin-top: -0.625rem;
`

const Input = styled.input`
  flex: 1;
  height: ${height}rem;
  border-radius: ${radius}rem;
  ${breakpoints.tablet`
    height: ${wideHeight}rem;
    border-radius: ${wideRadius}rem;
  `};
  color: ${({ theme }): Color => theme.colors.search.input};
  border-width: 0;
  font-size: 1.3em;
  width: 20vw;
  ${breakpoints.tablet`
    font-size: 1.1em;
  `};
  background-color: ${({ theme }): Color => theme.colors.search.background};
  padding: 0.3125rem 2.5rem;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${({ theme }): Color => theme.colors.search.placeholder};
  }
`

const SearchIconContainer = styled.div`
  width: ${height}rem;
  height: ${height}rem;
  ${breakpoints.tablet`
    width: ${wideHeight}rem;
    height: ${wideHeight}rem;
  `};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0.5rem;
  top: 0.35rem;
`

const ClearButtonContainer = styled.div`
  width: ${height}rem;
  height: ${height}rem;
  ${breakpoints.tablet`
    width: ${wideHeight}rem;
    height: ${wideHeight}rem;
  `};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0.5rem;
  top: 0.35rem;
  cursor: pointer;
`

const getPlaceHolderText = (filter: string | null): string => {
  switch (filter) {
    case FAVORITES_FILTER_VALUE:
      return TEXT_MY_FAVORITE_STATIONS
    case RECENTLY_PLAYED_FILTER_VALUE:
      return TEXT_RECENTLY_PLAYED_STATIONS
    case MOST_PLAYED_FILTER_VALUE:
      return TEXT_MOST_PLAYED_STATIONS
    default:
      return TEXT_ALL_STATIONS
  }
}

const Search: React.FC<Theme> = ({ theme, className }) => {
  const store = useContext(StoreContext)
  const { state, actions } = store
  const { filter } = state
  const { setFilter } = actions

  if (filter === CLEAR_COMMAND_VALUE) setFilter(null)

  const searchValue =
    !filter || [FAVORITES_FILTER_VALUE, RECENTLY_PLAYED_FILTER_VALUE, MOST_PLAYED_FILTER_VALUE].includes(filter) ? '' : filter

  const placeHolderText = getPlaceHolderText(filter)

  const clearFilter = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    setFilter(null)
  }

  return (
    <Container className={className}>
      <InputContainer>
        <Input onChange={(e): void => setFilter(e.target.value)} value={searchValue} placeholder={placeHolderText} />
        <SearchIconContainer>
          <SearchIcon fill={theme.colors.search.icon} />
        </SearchIconContainer>
        <ClearButtonContainer onClick={clearFilter}>
          {!!searchValue && <ClearIcon fill={theme.colors.search.clearControl} />}
        </ClearButtonContainer>
      </InputContainer>
    </Container>
  )
}

export default withTheme(Search)
