import React from 'react'
import styled from 'styled-components'

import StationGrid from 'src/components/station/StationGrid'

interface Props {
  className?: string
}

const Container = styled.div`
  grid-area: body;
  overflow-y: scroll;
`

const Body: React.FC<Props> = ({ className }) => (
  <Container>
    <StationGrid className={className} />
  </Container>
)

export default Body
Body.displayName = 'Body'
