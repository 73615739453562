import React from 'react'

import { Theme, Icon } from 'src/types'
import { withTheme } from 'styled-components'

interface Props extends Theme, Icon {}

const MoonIcon: React.FC<Props> = ({ theme, size = 1.45, fill = theme.colors.icon }) => {
  const width = `${size}rem`
  const height = `${(26 / 20) * size}rem`

  return (
    <svg width={width} height={height} viewBox="0 0 20 26">
      <path
        d="M13.472 3.5142C13.306 3.46396 13.122 3.51814 13.0097 3.65315C12.8979 3.78816 12.8773 3.97691 12.9577 4.13289C13.5716 5.32176 13.8827 6.5989 13.8827 7.92846C13.8827 12.5057 10.1588 16.23 5.58112 16.23C4.7715 16.23 3.96537 16.1112 3.18458 15.8753C3.01724 15.8254 2.83504 15.8801 2.72275 16.0142C2.6109 16.1492 2.58993 16.338 2.67032 16.4939C4.25461 19.5651 7.37863 21.4727 10.8247 21.4727C15.8838 21.4727 20.0001 17.3564 20.0001 12.2973C20.0001 8.28324 17.3156 4.67118 13.472 3.5142Z"
        fill={fill}
      />
    </svg>
  )
}

export default withTheme(MoonIcon)
MoonIcon.displayName = 'MoonIcon'
