import React, { ReactNode, useContext, useEffect } from 'react'
import styled, { withTheme, createGlobalStyle, FlattenSimpleInterpolation } from 'styled-components'
import { Color, GridTemplateRowsProperty } from 'csstype'
import Div100vh from 'react-div-100vh'

import { Theme } from 'src/types'
import { breakpoints } from 'src/styles'
import { StoreContext } from 'src/contexts/Store'
import { logPageview } from 'src/utils'

const GlobalStyle = createGlobalStyle`
  body {
    // @ts-ignore
    background-color: ${({ theme }): Color => theme.colors.panelBackground};
    font-size: 12px;
  }
  html, body {
     ${breakpoints.mobile`
       font-size: 12px;
    `}
  
    ${breakpoints.mobileLandscape`
       font-size: 13px;
    `}
  
    ${breakpoints.tablet`
      font-size: 15px;
    `}
  
    ${breakpoints.tabletLandscape`
      font-size: 15px;
    `}
    ${breakpoints.desktop`
      font-size: 17px;
    `}
  
    ${breakpoints.largeDesktop`
      font-size: 20px;
    `}
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
  }
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`

// eslint-disable-next-line react/jsx-props-no-spreading,@typescript-eslint/no-unused-vars
const GridContainer = styled(({ selectedStation, ...props }) => <Div100vh {...props} />)`
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: ${({ selectedStation }): GridTemplateRowsProperty<string> => (selectedStation ? '5rem 1fr 8rem' : '5rem 1fr')};
  ${({ selectedStation }): FlattenSimpleInterpolation => breakpoints.tablet`
    grid-template-rows: ${selectedStation ? '5rem 1fr 7rem' : '5rem 1fr'};
  `};
  background-color: ${({ theme }): Color => theme.colors.mainBackground};
`

interface Props extends Theme {
  children: ReactNode
}

const Main: React.FC<Props> = ({ children }) => {
  const store = useContext(StoreContext)
  const { state } = store
  const { selectedStation } = state

  useEffect(() => logPageview('/'), [])

  return (
    <>
      <GlobalStyle />
      <GridContainer selectedStation={!!selectedStation}>{children}</GridContainer>
    </>
  )
}

export default withTheme(Main)
Main.displayName = 'Main'
