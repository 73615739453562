/* eslint-disable import/no-duplicates */
import * as firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyCIcPXdsU20tbSY6PeW0tn3zDi_0D5gf3E',
  authDomain: 'zapp-242609.firebaseapp.com',
  databaseURL: 'https://zapp-242609.firebaseio.com',
  projectId: 'zapp-242609',
  storageBucket: 'zapp-242609.appspot.com',
  messagingSenderId: '72044832975',
  appId: '1:72044832975:web:465c8334265ac143'
}

firebase.initializeApp(firebaseConfig)

// firebase.database.enableLogging(true)

// eslint-disable-next-line import/prefer-default-export
export const db = firebase.database()
