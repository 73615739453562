import React, { SyntheticEvent, useContext } from 'react'
import styled, { withTheme } from 'styled-components'
import { Color } from 'csstype'
import Truncate from 'react-truncate'

import { StoreContext } from 'src/contexts/Store'
import { Station, IconState, Theme } from 'src/types'
import { EVENT_CLOSE_MODALS, GRID_THUMB_BASE_URL } from 'src/constants'
import { dimensions } from 'src/styles'
import { FavoriteIcon } from 'src/components/icons'
import { dispatcher } from 'src/utils'

const paddingSize = 0.4375

const Container = styled.div<{ selected: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: ${paddingSize}rem;
  padding-right: ${paddingSize}rem;
  padding-left: ${paddingSize}rem;
  background-color: ${({ theme, selected }): Color => (selected ? theme.colors.selectedBackground : theme.colors.mainBackground)};
  cursor: pointer;
  position: relative;
`

const StationItemImage = styled.div<{ imageUri: string; selected: boolean }>`
  overflow: hidden;
  height: 0;
  width: 100%;
  padding-top: 56.25%; // 400 / 225
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${({ theme }): Color => theme.colors.mainBackground};
  background-image: url(${({ imageUri }): string => imageUri});
  opacity: ${({ theme, selected }): number => (selected ? theme.station.item.selected.image.opacity : theme.station.item.image.opacity)};
`

const FavoriteControl = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-right: ${dimensions.paddingHorizontal}rem;
  padding-top: ${dimensions.paddingHorizontal - paddingSize}rem;
  width: ${dimensions.iconSize}rem;
  height: ${dimensions.iconSize}rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 0.3125rem 0;
  font-size: 0.9rem;
  height: 2.75rem;
  color: ${({ theme }): Color => theme.colors.station.meta};
  overflow: hidden;
  user-select: none;
`

interface Props extends Theme {
  station: Station
  selected: boolean
}

const StationGridItem: React.FC<Props> = ({ theme, station, selected }) => {
  const store = useContext(StoreContext)
  const { actions } = store
  const { toggleFavorite } = actions

  const { id, name, image, favorite, metadata } = station
  const imageUri = `${GRID_THUMB_BASE_URL}/${image}`

  const iconState = favorite ? IconState.active : IconState.normal

  const favoriteColor = theme.colors.station.favoriteControl[iconState]

  const onClickItem = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    actions.setStation(station.id)
    actions.setPlay(true)
  }

  const onClickFavorite = (e: SyntheticEvent): void => {
    dispatcher.emit(EVENT_CLOSE_MODALS)
    e.stopPropagation()
    toggleFavorite(id)
  }

  const text = (metadata && metadata.title) || name

  return (
    <Container onClick={onClickItem} selected={selected}>
      <StationItemImage imageUri={imageUri} selected={selected} />
      <FavoriteControl onClick={onClickFavorite}>
        <FavoriteIcon fill={favoriteColor} />
      </FavoriteControl>
      <TextContainer>
        <Truncate lines={2}>{text}</Truncate>
      </TextContainer>
    </Container>
  )
}

export default withTheme(StationGridItem)
StationGridItem.displayName = 'StationGridItem'
