import React from 'react'

import { Theme, Icon } from 'src/types'
import { withTheme } from 'styled-components'

interface Props extends Theme, Icon {}

const FavoriteIcon: React.FC<Props> = ({ theme, size = 1.55, fill = theme.colors.icon }) => {
  const width = `${size}rem`
  const height = `${size}rem`

  return (
    <svg width={width} height={height} viewBox="0 0 22 18">
      <path
        d="M12.3497 17.3072C11.5897 17.9972 10.4197 17.9972 9.65967 17.2972L9.54966 17.1972C4.29966 12.4472 0.869665 9.3372 0.999665 5.4572C1.05966 3.7572 1.92966 2.1272 3.33966 1.1672C5.97966 -0.632802 9.23967 0.207198 10.9997 2.2672C12.7597 0.207198 16.0197 -0.642802 18.6597 1.1672C20.0697 2.1272 20.9397 3.7572 20.9997 5.4572C21.1397 9.3372 17.6997 12.4472 12.4497 17.2172L12.3497 17.3072Z"
        fill={fill}
      />
    </svg>
  )
}

export default withTheme(FavoriteIcon)
FavoriteIcon.displayName = 'FavoriteIcon'
