import { Color } from 'csstype'

import { Theme as ITheme } from 'src/styles/themes'

export interface StringTMap<T> {
  [key: string]: T
}
export interface NumberTMap<T> {
  [key: number]: T
}

export interface MetaData {
  id: string
  name: string
  title: string
}

export interface Statistics {
  lastPlayed: number
  playCount: number
}

export type StationsStatistics = StringTMap<Statistics>

export interface StationBase {
  id: string
  name: string
  slug: string
  image: string
  asx: string
  pls: string
  stream: string
  tags: Tags
  position: number
}

export interface Station extends StationBase {
  selected: boolean
  favorite: boolean
  metadata?: MetaData
  statistics?: Statistics
}

export type Tags = string[]

export enum ColorFill {
  'default',
  'inverted'
}

export interface Icon {
  size?: number
  fill?: Color
}

export enum IconState {
  normal,
  active
}

export interface Theme {
  theme: ITheme
  className?: string
}

export enum AnalyticsCategory {
  DEFAULT = 'default',
  PLAYBACK = 'playback'
}

export enum AnalyticsEvent {
  PLAY = 'play'
}
