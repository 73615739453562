import React from 'react'

import { Theme, Icon } from 'src/types'
import { withTheme } from 'styled-components'

interface Props extends Theme, Icon {}

const SearchIcon: React.FC<Props> = ({ theme, size = 1, fill = theme.colors.icon }) => {
  const width = `${size}rem`
  const height = `${size}rem`

  return (
    <svg width={width} height={height} viewBox="0 0 16 16">
      <path
        d="M12.803 11.2966C13.8633 9.85643 14.3404 8.0688 14.1386 6.2918C13.9368 4.51479 13.0711 2.87963 11.7149 1.71383C10.3586 0.548033 8.61201 -0.0623165 6.82485 0.00503531C5.03769 0.0723871 3.34197 0.812466 2.07733 2.07704C0.812683 3.34161 0.0725058 5.03729 0.00505076 6.82444C-0.0624043 8.61159 0.547844 10.3582 1.71356 11.7145C2.87928 13.0709 4.5144 13.9367 6.29139 14.1385C8.06838 14.3404 9.85604 13.8635 11.2963 12.8032L14.1803 15.6871C14.2792 15.7862 14.3967 15.8649 14.526 15.9186C14.6553 15.9722 14.7939 15.9999 14.9339 16C15.0739 16.0001 15.2126 15.9726 15.342 15.919C15.4713 15.8655 15.5889 15.7869 15.6879 15.6879C15.7869 15.5889 15.8654 15.4714 15.9189 15.342C15.9724 15.2126 16 15.074 15.9999 14.9339C15.9998 14.7939 15.9721 14.6553 15.9184 14.526C15.8647 14.3967 15.786 14.2793 15.6869 14.1804L12.803 11.2966ZM7.10089 12.0531C6.12147 12.0531 5.16404 11.7626 4.34969 11.2185C3.53533 10.6743 2.90062 9.90091 2.52582 8.99604C2.15102 8.09117 2.05296 7.09547 2.24404 6.13487C2.43512 5.17427 2.90677 4.2919 3.59933 3.59935C4.29189 2.90679 5.17426 2.43516 6.13487 2.24409C7.09548 2.05302 8.09117 2.1511 8.99603 2.52591C9.9009 2.90073 10.6743 3.53545 11.2184 4.34981C11.7626 5.16418 12.053 6.12161 12.053 7.10103C12.0515 8.41394 11.5293 9.67265 10.6009 10.601C9.67252 11.5294 8.4138 12.0516 7.10089 12.053V12.0531Z"
        fill={fill}
      />
    </svg>
  )
}

export default withTheme(SearchIcon)
SearchIcon.displayName = 'SearchIcon'
