import React, { useContext } from 'react'
import ReactHowler from 'react-howler'

import { StoreContext } from 'src/contexts/Store'

export default (): JSX.Element => {
  const store = useContext(StoreContext)
  const { state, actions } = store
  const { selectedStation, isPlay = false } = state
  const { setLoading } = actions

  if (selectedStation) {
    if (isPlay) {
      const { stream } = selectedStation
      const onLoadStart = (): void => {
        setLoading(true)
      }

      const onLoad = (): void => {
        setLoading(false)
      }

      const onError = (): void => {
        // setPlay(false) /* consciously not doing this, to prevent 'blinking' behavior */
        setLoading(false)
      }

      return <ReactHowler src={stream} html5 onLoad={onLoadStart} onPlay={onLoad} onLoadError={onError} />
    }
    setLoading(false)
  }

  return <></>
}
