import React from 'react'

import { Theme, Icon } from 'src/types'
import { withTheme } from 'styled-components'

interface Props extends Theme, Icon {}

const OptionsRightIcon: React.FC<Props> = ({ theme, size = 1.55, fill = theme.colors.icon }) => {
  const width = `${size}rem`
  const height = `${size}rem`

  return (
    <svg viewBox="0 0 662 662" width={width} height={height}>
      <circle cx="200" cy="112.6" r="60" fill={fill} />
      <circle cx="350" cy="335.5" r="60" fill={fill} />
      <circle cx="200" cy="558.4" r="60" fill={fill} />
    </svg>
  )
}

export default withTheme(OptionsRightIcon)
OptionsRightIcon.displayName = 'OptionsRightIcon'
