import React, { SyntheticEvent, useContext } from 'react'
import styled, { withTheme } from 'styled-components'
import { Color } from 'csstype'
import Truncate from 'react-truncate'

import { StoreContext } from 'src/contexts/Store'
import { Station, IconState, Theme } from 'src/types'
import { EVENT_CLOSE_MODALS, THUMBNAIL_BASE_URL } from 'src/constants'
import { breakpoints, dimensions } from 'src/styles'
import { FavoriteIcon } from 'src/components/icons'
import { dispatcher } from 'src/utils'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 6rem 1fr 3rem;
  grid-template-areas: 'image text favorite-toggle';
  ${breakpoints.tablet`
    grid-template-columns: 10.5rem 1.5rem 1fr;
    grid-template-areas: 'image favorite-toggle text';
  `};
  padding: ${dimensions.paddingHorizontal}rem 0;
  user-select: none;
`

const StationImage = styled.img`
  grid-area: image;
  margin-left: ${dimensions.paddingHorizontal}rem;
  margin-right: ${dimensions.paddingHorizontal}rem;
  width: 4.375rem;
  height: 2.5rem;
  ${breakpoints.tablet`
    width: 8.75rem;
    height: 5rem;
  `};
  background-color: ${({ theme }): Color => theme.colors.image.background};
  border-width: 1px;
  border-color: ${({ theme }): Color => theme.colors.image.border};
  border-style: solid;
  opacity: ${({ theme }): Color => theme.station.item.selected.image.opacity};
`

const FavoriteControl = styled.div`
  grid-area: favorite-toggle;
  padding-right: ${dimensions.paddingHorizontal}rem;
  ${breakpoints.tablet`
    padding-right: 0;
  `};
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  ${breakpoints.tablet`
    align-items: flex-start;
    justify-content: center;
  `};
  cursor: pointer;
`

const TextContainer = styled.div`
  grid-area: text;
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`

const Name = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }): Color => theme.colors.text};
`

const Meta = styled.div`
  font-size: 1rem;
  color: ${({ theme }): Color => theme.colors.text};
`

interface Props extends Theme {
  station: Station
}

const StationNowPlaying: React.FC<Props> = ({ station, className, theme }) => {
  const store = useContext(StoreContext)
  const { actions } = store

  const { id, name, image, favorite, metadata } = station
  const imageUri = `${THUMBNAIL_BASE_URL}/${image}`

  const iconState = favorite ? IconState.active : IconState.normal

  const favoriteColor = theme.colors.favoriteControl[iconState]

  const toggleFavorite = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    actions.toggleFavorite(id)
  }

  return (
    <GridContainer className={className}>
      <StationImage src={imageUri} alt={name} />
      <TextContainer>
        <Name>
          <Truncate>{name}</Truncate>
        </Name>
        {metadata && (
          <Meta>
            <Truncate>{metadata.title}</Truncate>
          </Meta>
        )}
      </TextContainer>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <FavoriteControl onClick={toggleFavorite}>
        <FavoriteIcon fill={favoriteColor} />
      </FavoriteControl>
    </GridContainer>
  )
}

export default withTheme(StationNowPlaying)
