import { IconState } from 'src/types'
import palette from './palette'
import { Colors } from './colors'
import { Theme } from './themes'

const colors: Colors = {
  text: palette.black,
  mainBackground: palette.white,
  panelBackground: palette.gray15,
  selectedBackground: palette.gray12,
  border: palette.gray20,
  control: palette.nearlyBlack,
  loading: palette.primaryLight,
  logo: palette.nearlyBlack,
  icon: palette.gray30,
  station: {
    name: palette.nearlyBlack,
    meta: palette.gray70,
    favoriteControl: {
      [IconState.normal]: palette.gray30,
      [IconState.active]: palette.red
    }
  },
  favoriteControl: {
    [IconState.normal]: palette.gray30,
    [IconState.active]: palette.red
  },
  themeControl: {
    [IconState.normal]: palette.gray30,
    [IconState.active]: palette.yellow
  },
  filterControl: {
    [IconState.normal]: palette.gray30,
    [IconState.active]: palette.red
  },
  search: {
    input: palette.nearlyBlack,
    selection: palette.primary,
    background: palette.gray20,
    placeholder: palette.gray50,
    icon: palette.gray50,
    clearControl: palette.gray45
  },
  popover: {
    background: palette.white,
    text: palette.gray50,
    hover: {
      background: palette.gray15,
      text: palette.gray50
    }
  },
  image: {
    background: palette.gray20,
    border: palette.gray20
  }
}

export default {
  colors,
  station: {
    item: {
      image: {
        opacity: 1
      },
      selected: {
        image: {
          opacity: 1
        }
      }
    }
  }
} as Theme
