import ReactGA from 'react-ga'

import { AnalyticsCategory, AnalyticsEvent, StationBase } from 'src/types'
import { GA_UA_IDENTIFIER } from 'src/constants'

ReactGA.initialize(GA_UA_IDENTIFIER)

export const logPageview = (path: string): void => ReactGA.pageview(path)

// eslint-disable-next-line @typescript-eslint/ban-types,import/prefer-default-export
export const logEvent = (event: AnalyticsEvent, label?: string, value?: number): void =>
  ReactGA.event({
    category: AnalyticsCategory.DEFAULT,
    action: event,
    label,
    value
  })

export const logEventPlay = (station: StationBase | null): void => {
  ReactGA.event({
    category: AnalyticsCategory.PLAYBACK,
    action: AnalyticsEvent.PLAY,
    label: station ? station.name : 'none'
  })
}
