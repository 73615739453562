const IMAGE_BASE_URL = 'https://static.zapp.fm/images/'

export const THUMBNAIL_BASE_URL = `${IMAGE_BASE_URL}210x120`
export const GRID_THUMB_BASE_URL = `${IMAGE_BASE_URL}400x225`

export const HANDLE_STATISTICS_DELAY = 2000

export const MIN_FILTER_VALUE_LENGTH = 3

export const FAVORITES_FILTER_VALUE = ':favorites'
export const RECENTLY_PLAYED_FILTER_VALUE = ':recent'
export const MOST_PLAYED_FILTER_VALUE = ':most'
export const CLEAR_COMMAND_VALUE = ':reset'

export const STORE_KEY_FAVORITE_STATION_IDS = '@STORE_KEY_FAVORITE_STATION_IDS'
export const STORE_KEY_STATISTICS = '@STORE_KEY_STATISTICS'
export const STORE_KEY_FILTER_VALUE = '@STORE_KEY_FILTER_VALUE'
export const STORE_KEY_SELECTED_STATION_ID = '@STORE_KEY_SELECTED_STATION_ID'
export const STORE_KEY_THEME_NAME = '@STORE_KEY_THEME_NAME'

export const TEXT_ALL_STATIONS = 'Alle Stations'

export const TEXT_MY_FAVORITE_STATIONS = 'Mijn Favorieten'
export const TEXT_RECENTLY_PLAYED_STATIONS = 'Recent beluisterd'
export const TEXT_MOST_PLAYED_STATIONS = 'Meest beluisterd'

export const TEXT_SITE_TITLE = 'Zapp FM'

export const GA_UA_IDENTIFIER = 'UA-73136876-1'

export const EVENT_CLOSE_MODALS = '@EVENT_CLOSE_MODALS'

export const APP_SET_PLAY = '@APP_SET_PLAY'
export const APP_TOGGLE_PLAY = '@APP_TOGGLE_PLAY'
export const APP_STATION_NEXT = '@APP_STATION_NEXT'
export const APP_STATION_PREV = '@APP_STATION_PREV'
