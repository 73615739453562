import React from 'react'
import { withTheme } from 'styled-components'
import { Color } from 'csstype'

import { Theme } from 'src/types'

interface Props extends Theme {
  size?: number
  fill?: Color
}

const Logo: React.FC<Props> = ({ theme, size = 3, fill = theme.colors.logo }) => {
  const width = `${size}rem`
  const height = `${(231.3 / 502.1) * size}rem`

  return (
    <svg width={width} height={height} viewBox="0 0 502.1 231.3">
      <polygon points="11.7,44.4 20.3,34.8 253.2,4.2 112.5,175.8 498.9,175.8 4,227.1 168.1,44.4" fill={fill} />
      <path
        d="M242.7,134.6h-32.8L197.8,150h-29.5l80.8-93.6h31.5L270.1,150h-30.3L242.7,134.6z M246.6,114.3l6.1-33.6L226,114.3H246.6z"
        fill={fill}
      />
      <path
        d="M322.1,56.5h48.1c10.5,0,17.1,2.5,19.9,7.5c2.8,5,1.9,12.1-2.5,21.3c-4.6,9.4-11,16.8-19.3,22.1c-8.3,5.3-18.2,8-29.9,8 h-15.8L305.5,150h-29L322.1,56.5z M331.7,96.3h7.1c5.6,0,10-1,13.2-2.9c3.2-1.9,5.5-4.4,7-7.4c1.4-2.9,1.7-5.4,0.7-7.5 c-1-2-4.1-3.1-9.5-3.1h-8.2L331.7,96.3z"
        fill={fill}
      />
      <path
        d="M409.7,56.5h48.1c10.5,0,17.1,2.5,19.9,7.5c2.8,5,1.9,12.1-2.5,21.3c-4.6,9.4-11,16.8-19.3,22.1c-8.3,5.3-18.2,8-29.9,8 h-15.8L393.2,150h-29L409.7,56.5z M419.3,96.3h7.1c5.6,0,10-1,13.2-2.9c3.2-1.9,5.5-4.4,7-7.4c1.4-2.9,1.7-5.4,0.7-7.5 c-1-2-4.1-3.1-9.5-3.1h-8.2L419.3,96.3z"
        fill={fill}
      />
    </svg>
  )
}

export default withTheme(Logo)
Logo.displayName = 'Logo'
