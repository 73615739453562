import React, { SyntheticEvent, useState, useContext, useEffect, useCallback, ReactElement } from 'react'
import styled, { withTheme } from 'styled-components'
import Popover from 'react-tiny-popover'

import { StoreContext } from 'src/contexts/Store'
import { dispatcher } from 'src/utils'
import { IconState, Theme } from 'src/types'
import { MOST_PLAYED_FILTER_VALUE, RECENTLY_PLAYED_FILTER_VALUE, EVENT_CLOSE_MODALS } from 'src/constants'
import { OptionsRightIcon } from 'src/components/icons'
import FilterOptions from './FilterOptions'

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
`

const FilterControl: React.FC<Theme> = ({ className, theme }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const store = useContext(StoreContext)
  const { state, actions } = store
  const { filter } = state
  const { setFilter } = actions

  const memoizedClose = useCallback((): void => {
    setIsOpen(false)
  }, [setIsOpen])

  const memoizedOnOptionClick = useCallback(
    (filterValue: string | null): void => {
      setFilter(filterValue)
      setIsOpen(false)
    },
    [setFilter, setIsOpen]
  )

  useEffect((): (() => void) => {
    dispatcher.on(EVENT_CLOSE_MODALS, memoizedClose)
    return (): void => {
      dispatcher.removeAllListeners(EVENT_CLOSE_MODALS)
    }
  }, [memoizedClose])

  const active = [MOST_PLAYED_FILTER_VALUE, RECENTLY_PLAYED_FILTER_VALUE].includes(filter || '')
  const iconFill = theme.colors.filterControl[IconState[active ? 'active' : 'normal']]

  const toggleFilterOptions = (e: SyntheticEvent): void => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  return (
    <Popover
      isOpen={isOpen}
      position={['bottom', 'right', 'left']}
      padding={10}
      onClickOutside={memoizedClose}
      content={(): ReactElement => <FilterOptions filter={filter} onOptionClick={memoizedOnOptionClick} theme={theme} />}
    >
      <Container className={className} onClick={toggleFilterOptions}>
        <OptionsRightIcon fill={iconFill} />
      </Container>
    </Popover>
  )
}

export default withTheme(FilterControl)
FilterControl.displayName = 'FilterControl'
