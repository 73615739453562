import React from 'react'

import { Theme, Icon } from 'src/types'
import { withTheme } from 'styled-components'

interface Props extends Theme, Icon {}

const StopIcon: React.FC<Props> = ({ theme, size = 2.5, fill = theme.colors.control }) => {
  const width = `${size}rem`
  const height = `${size}rem`

  return (
    <svg width={width} height={height} viewBox="0 0 20 20">
      <path d="M17.6 16.3c0 .7-.6 1.2-1.2 1.2H3.7c-.7 0-1.2-.6-1.2-1.2V3.7C2.4 3 3 2.4 3.7 2.4h12.6c.7 0 1.2.6 1.2 1.2v12.7z" fill={fill} />
    </svg>
  )
}

export default withTheme(StopIcon)
StopIcon.displayName = 'StopIcon'
