import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'
import { Color } from 'csstype'

import { Theme } from 'src/types'
import { breakpoints } from 'src/styles'
import {
  MOST_PLAYED_FILTER_VALUE,
  RECENTLY_PLAYED_FILTER_VALUE,
  TEXT_ALL_STATIONS,
  TEXT_MOST_PLAYED_STATIONS,
  TEXT_RECENTLY_PLAYED_STATIONS
} from 'src/constants'
import FilterOption from './FilterOption'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background-color: ${({ theme }): Color => theme.colors.popover.background};
  border-color: ${({ theme }): Color => theme.colors.border};
  border-radius: 0.5rem;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  font-size: 1.3rem;
  margin-top: 0.4rem;
  ${breakpoints.tablet`
    font-size: 1rem;
    margin-top: 0.6rem;
  `};
`

const Divider = styled.div`
  flex: 1;
  padding: 0;
  border-top: 1px solid ${({ theme }): Color => theme.colors.border};
  margin: 0.1rem 0;
`

interface Props extends Theme {
  filter: string | null
  onOptionClick: (filterValue: string | null) => void
}

const FilterOptions: React.FC<Props> = ({ theme, filter, onOptionClick }) => {
  const setFilterMostPlayed = (e: SyntheticEvent): void => {
    e.stopPropagation()
    onOptionClick(MOST_PLAYED_FILTER_VALUE)
  }

  const setFilterRecentlyPlayed = (e: SyntheticEvent): void => {
    e.stopPropagation()
    onOptionClick(RECENTLY_PLAYED_FILTER_VALUE)
  }

  const clearFilter = (e: SyntheticEvent): void => {
    e.stopPropagation()
    onOptionClick(null)
  }

  return (
    <Container theme={theme}>
      <FilterOption
        label={TEXT_RECENTLY_PLAYED_STATIONS}
        active={filter === RECENTLY_PLAYED_FILTER_VALUE}
        onClick={setFilterRecentlyPlayed}
        theme={theme}
      />
      <FilterOption
        label={TEXT_MOST_PLAYED_STATIONS}
        active={filter === MOST_PLAYED_FILTER_VALUE}
        onClick={setFilterMostPlayed}
        theme={theme}
      />
      <Divider theme={theme} />
      <FilterOption label={TEXT_ALL_STATIONS} active={filter === null} onClick={clearFilter} theme={theme} />
    </Container>
  )
}

export default FilterOptions
FilterOptions.displayName = 'FilterOptions'
