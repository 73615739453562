import React, { useContext } from 'react'
import styled, { withTheme } from 'styled-components'
import { Color } from 'csstype'

import { Theme } from 'src/types'
import { StoreContext } from 'src/contexts/Store'
import { breakpoints } from 'src/styles'
import StationNowPlaying from 'src/components/station/StationNowPlaying'
import AudioControls from 'src/components/media/AudioControls'

const GridContainer = styled.div`
  grid-area: footer;
  background-color: ${({ theme }): Color => theme.colors.panelBackground};
  border-top-color: ${({ theme }): Color => theme.colors.border};
  border-top-width: 1px;
  border-top-style: solid;
  padding-bottom: 1rem;
  display: grid;
  grid-template-areas:
    'now-playing'
    'media-controls';
  ${breakpoints.tablet`
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      'now-playing media-controls'
  `}
`

const NowPlaying = styled(StationNowPlaying)`
  grid-area: now-playing;
`

const StyledAudioControls = styled(AudioControls)`
  grid-area: media-controls;
`

const Footer: React.FC<Theme> = () => {
  const store = useContext(StoreContext)
  const { state } = store
  const { selectedStation } = state

  if (!selectedStation) return <></>

  return (
    <GridContainer>
      {selectedStation && (
        <>
          <NowPlaying station={selectedStation} />
          <StyledAudioControls />
        </>
      )}
    </GridContainer>
  )
}

export default withTheme(Footer)
Footer.displayName = 'Footer'
