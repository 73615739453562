import { Colors } from 'src/styles/colors'
import light from './light'
import dark from './dark'

export interface Theme {
  colors: Colors
  station: {
    item: {
      image: {
        opacity: number
      }
      selected: {
        image: {
          opacity: number
        }
      }
    }
  }
}

export interface Themes {
  light: Theme
  dark: Theme
}

export default {
  light,
  dark
} as Themes
