import React, { Context, useState, createContext, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'

import { themes } from 'src/styles'
import { STORE_KEY_THEME_NAME } from 'src/constants'
import { getString, store } from 'src/utils/persist'

type ThemeName = 'light' | 'dark'

interface UiState {
  themeName: ThemeName
}

export interface UiActions {
  setThemeName: (value: ThemeName) => void
  toggleTheme: () => void
}

interface UiContextValue {
  state: UiState
  actions: UiActions
}

// @ts-ignore
export const UiContext: Context<UiContextValue> = createContext({
  state: {
    theme: 'light'
  },
  /* eslint-disable no-unused-vars,@typescript-eslint/no-unused-vars */
  actions: {
    setThemeName: (value: ThemeName): void => {},
    toggleTheme: (): void => {}
  }
  /* eslint-enable no-unused-vars,@typescript-eslint/no-unused-vars */
})

interface Props {
  children: JSX.Element
}

export const UiProvider: React.FC<Props> = ({ children }) => {
  const [themeName, setThemeName] = useState<ThemeName>((getString(STORE_KEY_THEME_NAME) as ThemeName) || 'light')

  useEffect(() => {
    store(STORE_KEY_THEME_NAME, themeName)
  }, [themeName])

  const value: UiContextValue = {
    state: {
      themeName
    },
    actions: {
      setThemeName: (t: ThemeName): void => setThemeName(t),
      toggleTheme: (): void => setThemeName(themeName === 'light' ? 'dark' : 'light')
    }
  }

  return (
    <UiContext.Provider value={value}>
      <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
    </UiContext.Provider>
  )
}
