import React, { useContext } from 'react'

import { StoreContext } from 'src/contexts/Store'
import { TEXT_SITE_TITLE } from 'src/constants'

const HtmlHead: React.FC = () => {
  const store = useContext(StoreContext)
  const { state } = store
  const { selectedStation } = state

  let title: string
  if (selectedStation) {
    const { name, metadata } = selectedStation
    title = (metadata && metadata.title) || name
  } else {
    title = TEXT_SITE_TITLE
  }

  document.title = title

  return null
}

export default HtmlHead
