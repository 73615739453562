import React, { Context, useEffect, useState, createContext, useCallback, useMemo } from 'react'
import { db } from 'src/connectors/firebase'
import isElectron from 'is-electron'

import { getString, getStringArray, getObject, store, removeKeys } from 'src/utils/persist'
import { MetaData, Station, StationBase, StationsStatistics } from 'src/types'
import { logEventPlay, isMatchForSearchValue } from 'src/utils'
import {
  HANDLE_STATISTICS_DELAY,
  MIN_FILTER_VALUE_LENGTH,
  FAVORITES_FILTER_VALUE,
  MOST_PLAYED_FILTER_VALUE,
  RECENTLY_PLAYED_FILTER_VALUE,
  CLEAR_COMMAND_VALUE,
  STORE_KEY_FAVORITE_STATION_IDS,
  STORE_KEY_STATISTICS,
  STORE_KEY_FILTER_VALUE,
  STORE_KEY_SELECTED_STATION_ID,
  APP_SET_PLAY,
  APP_TOGGLE_PLAY,
  APP_STATION_NEXT,
  APP_STATION_PREV
} from 'src/constants'

const stationRef = db.ref('/nl/stations')
const metaDataRef = db.ref(`/nl/metadata`)

const unsubscribeStations = (): void => {
  stationRef.off()
  metaDataRef.off()
}

const getMetaDataForStation = (baseStation: StationBase, metaData: MetaData[]): MetaData | undefined =>
  metaData.find((m): boolean => m.id === baseStation.id)

/* eslint-disable no-underscore-dangle */

/**
 * Use old fashioned module state here, as the state is lagging behind and
 * above all we do not want to trigger unnecessary state updates triggering
 * a functional component JSX update/re-render
 */

let __handleStatisticsTimerId: number | null = null
let __statisticsProcessed = false
let __isPlaying = false
let __stationId: string | null = null

const clearPersistedState = (): void => {
  removeKeys([STORE_KEY_STATISTICS, STORE_KEY_FILTER_VALUE, STORE_KEY_SELECTED_STATION_ID, STORE_KEY_FAVORITE_STATION_IDS])
}

interface StoreState {
  isPlay: boolean
  isLoading: boolean
  isFetching: boolean
  stations: Station[]
  metadata: MetaData[]
  selectedStation: Station | null
  favoriteStationIds: string[]
  filter: string | null
}

export interface StoreActions {
  setPlay: (playing: boolean) => void
  setLoading: (loading: boolean) => void
  togglePlay: () => void
  refetchStations: () => void
  setStation: (id: string) => void
  setNextStation: () => void
  setPrevStation: () => void
  toggleFavorite: (id: string) => void
  toggleFavoritesFilter: () => void
  setFilter: (value: string | null) => void
}

interface StoreContextValue {
  state: StoreState
  actions: StoreActions
}

// @ts-ignore
export const StoreContext: Context<StoreContextValue> = createContext({
  state: {
    isPlay: false,
    isLoading: false,
    stations: [],
    metadata: [],
    selectedStation: null,
    favoriteStationIds: [],
    filter: null
  },
  /* eslint-disable no-unused-vars,@typescript-eslint/no-unused-vars */
  actions: {
    setPlay: (playing: boolean): void => {},
    setLoading: (loading: boolean): void => {},
    togglePlay: (): void => {},
    refetchStations: (): void => {},
    setStation: (id: string): void => {},
    setNextStation: (): void => {},
    setPrevStation: (): void => {},
    toggleFavorite: (id: string): void => {},
    toggleFavoritesFilter: (): void => {},
    setFilter: (value: string | null): void => {}
  }
  /* eslint-enable no-unused-vars,@typescript-eslint/no-unused-vars */
})

export const StoreProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const [stations, setStations] = useState<StationBase[]>([])
  const [metadata, setMetadata] = useState<MetaData[]>([])
  const [favoriteStationIds, setFavoriteStationIds] = useState<string[]>(getStringArray(STORE_KEY_FAVORITE_STATION_IDS))
  const [filter, setFilter] = useState<string | null>(getString(STORE_KEY_FILTER_VALUE))
  const [selectedStationId, setSelectedStationId] = useState<string | null>(getString(STORE_KEY_SELECTED_STATION_ID))
  const [isPlay, setIsPlay] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [statistics, setStatistics] = useState<StationsStatistics>(getObject(STORE_KEY_STATISTICS) as StationsStatistics)

  const subscribeToMetaData = useCallback((): void => {
    metaDataRef.on('value', (snapshot): void => {
      setMetadata(Object.values(snapshot.val()))
    })
  }, [])

  const fetchStations = useCallback((): void => {
    setIsFetching(true)
    stationRef.on(
      'value',
      (snapshot): void => {
        // @ts-ignore
        const data = Object.values(snapshot.val()).sort((a, b): number => a.position - b.position)
        // @ts-ignore
        setStations(data)
        setIsFetching(false)
        subscribeToMetaData()
      },
      (err: Error): void => {
        setIsFetching(false)
        // eslint-disable-next-line no-console
        console.log('Error fetching data-----------', err)
      }
    )
  }, [subscribeToMetaData])

  const refreshStations = useCallback((): void => {
    unsubscribeStations()
    fetchStations()
  }, [fetchStations])

  useEffect((): (() => void) => {
    fetchStations()
    return unsubscribeStations
  }, [fetchStations])

  const enrichStation = useCallback(
    (station: StationBase): Station => ({
      ...station,
      selected: !!selectedStationId && selectedStationId === station.id,
      favorite: favoriteStationIds.includes(station.id),
      metadata: getMetaDataForStation(station, metadata),
      statistics: statistics && statistics[station.id]
    }),
    [selectedStationId, favoriteStationIds, metadata, statistics]
  )

  const getSortedStationsForStatistic = useCallback(
    (baseStations: StationBase[], metric: 'playCount' | 'lastPlayed', limit = 6): Station[] =>
      baseStations
        .map(enrichStation)
        .filter(s => s.statistics)
        .sort((a, b) => ((a.statistics ? a.statistics[metric] : 0) > (b.statistics ? b.statistics[metric] : 0) ? -1 : 1))
        .slice(0, limit),
    [enrichStation]
  )

  const filteredStations = useMemo((): Station[] => {
    // TODO: Set window resize hook to always be able to know how many station columns we display...
    const limit = 12 // getNumberOfColumns() * 2,
    if (!filter) return stations.map(enrichStation)
    switch (filter) {
      case FAVORITES_FILTER_VALUE:
        return stations.filter((s): boolean => favoriteStationIds.includes(s.id)).map(enrichStation)
      case MOST_PLAYED_FILTER_VALUE:
        return getSortedStationsForStatistic(stations, 'playCount', limit)
      case RECENTLY_PLAYED_FILTER_VALUE:
        return getSortedStationsForStatistic(stations, 'lastPlayed', limit)
      default:
        return stations.filter((s): boolean => isMatchForSearchValue(s, filter)).map(enrichStation)
    }
  }, [stations, filter, enrichStation, favoriteStationIds, getSortedStationsForStatistic])

  const currentStationIndex = useMemo(
    (): number => (selectedStationId ? filteredStations.findIndex((s: Station): boolean => s.id === selectedStationId) : -1),
    [selectedStationId, filteredStations]
  )

  // TODO: Can be moved to a useEffect hook
  const setFavoriteStations = (stationIds: string[]): void => {
    setFavoriteStationIds(stationIds)
    store(STORE_KEY_FAVORITE_STATION_IDS, stationIds)
  }

  const toggleFavorite = useCallback(
    (id: string): void => {
      if (favoriteStationIds.includes(id)) {
        setFavoriteStations(favoriteStationIds.filter((value: string): boolean => value !== id))
      } else {
        setFavoriteStations([...favoriteStationIds, id])
      }
    },
    [favoriteStationIds]
  )

  const setFilterValue = async (value: string | null): Promise<void> => {
    if (filter === CLEAR_COMMAND_VALUE) {
      await clearPersistedState()
      setFilter(null)
      setStatistics({})
      setFavoriteStationIds([])
    } else {
      setFilter(value)
      store(STORE_KEY_FILTER_VALUE, value && value.length >= MIN_FILTER_VALUE_LENGTH ? value : null)
    }
  }

  const toggleFavoritesFilter = (): void => {
    setFilterValue(filter !== FAVORITES_FILTER_VALUE ? FAVORITES_FILTER_VALUE : null)
  }

  const getStationById = useCallback((id: string): StationBase | null => stations.find((s): boolean => s.id === id) || null, [stations])

  const handleStatistics = useCallback((): void => {
    if (statistics && !__statisticsProcessed) {
      if (__handleStatisticsTimerId) clearTimeout(__handleStatisticsTimerId)
      // @ts-ignore
      __handleStatisticsTimerId = setTimeout(() => {
        if (__isPlaying && __stationId) {
          logEventPlay(getStationById(__stationId))
          const playCount = (statistics[__stationId] ? statistics[__stationId].playCount : 0) + 1
          const lastPlayed = Date.now()
          const updatedStatistics = {
            ...statistics,
            [__stationId]: {
              lastPlayed,
              playCount
            }
          }
          setStatistics(updatedStatistics)
          store(STORE_KEY_STATISTICS, updatedStatistics)
          __statisticsProcessed = true
        }
      }, HANDLE_STATISTICS_DELAY)
    }
  }, [getStationById, statistics])

  const setStation = useCallback(
    (id: string): void => {
      const previousStationId = __stationId
      if (previousStationId !== id) {
        __statisticsProcessed = false
        __stationId = id
        setSelectedStationId(id)
        store(STORE_KEY_SELECTED_STATION_ID, id)
        handleStatistics()
      }
    },
    [setSelectedStationId, handleStatistics]
  )

  const setNextStation = useCallback((): void => {
    if (!stations.length) return
    const nextIndex = currentStationIndex + 1
    const maxIndex = filteredStations.length - 1
    const nextStation = filteredStations[nextIndex > maxIndex ? 0 : nextIndex]
    if (nextStation) setStation(nextStation.id)
  }, [currentStationIndex, filteredStations, stations, setStation])

  const setPrevStation = useCallback((): void => {
    if (!stations.length) return
    const prevIndex = currentStationIndex - 1
    const maxIndex = filteredStations.length - 1
    const prevStation = filteredStations[prevIndex < 0 ? maxIndex : prevIndex]
    if (prevStation) setStation(prevStation.id)
  }, [currentStationIndex, filteredStations, stations, setStation])

  const setPlay = useCallback(
    (playing: boolean): void => {
      setIsPlay(playing)
      __isPlaying = playing
      handleStatistics()
    },
    [setIsPlay, handleStatistics]
  )

  const togglePlay = useCallback((): void => {
    setPlay(!isPlay)
  }, [setPlay, isPlay])

  const setLoading = (loading: boolean): void => {
    setIsLoading(loading)
  }

  let selectedStation: Station | null = null
  if (selectedStationId) {
    const s = getStationById(selectedStationId)
    if (s) {
      selectedStation = enrichStation(s)
    }
  }

  /* Electron required eventHandlers */
  useEffect(() => {
    // @ts-ignore
    if (isElectron() && window.ipcRenderer) {
      const onSetPlayEvent = (event: Event, value: boolean): void => setPlay(value)
      // @ts-ignore
      const { ipcRenderer: ipc } = window
      ipc.on(APP_SET_PLAY, onSetPlayEvent)
      ipc.on(APP_TOGGLE_PLAY, togglePlay)
      ipc.on(APP_STATION_NEXT, setNextStation)
      ipc.on(APP_STATION_PREV, setPrevStation)
      return (): void => {
        ipc.removeListener(APP_SET_PLAY, onSetPlayEvent)
        ipc.removeListener(APP_TOGGLE_PLAY, togglePlay)
        ipc.removeListener(APP_STATION_NEXT, setNextStation)
        ipc.removeListener(APP_STATION_PREV, setPrevStation)
      }
    }
    return (): void => {}
  }, [setPlay, togglePlay, setNextStation, setPrevStation])
  /* End of Electron required eventHandlers */

  const value: StoreContextValue = {
    state: {
      isPlay,
      isLoading,
      isFetching,
      stations: filteredStations,
      metadata,
      selectedStation,
      favoriteStationIds,
      filter
    },
    actions: {
      setPlay,
      setLoading,
      togglePlay,
      refetchStations: refreshStations,
      setStation,
      setNextStation,
      setPrevStation,
      toggleFavorite,
      toggleFavoritesFilter,
      setFilter: setFilterValue
    }
  }

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

/* eslint-enable no-underscore-dangle */
